import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import AdminRegistration from '../../../../components/pages/private/admin/registrations/registration-view';
import PrivateRoute from '../../../../components/private-route';
import { isBrowser } from '../../../../utils/helpers';

export default function RegistrationView() {
  const [id, setId] = useState(null);
  const [init, setInit] = useState(false);
  useEffect(() => {
    const params = queryString.parse(isBrowser ? window.location.search : '');
    if (params && params.id) {
      setId(params.id);
    }
    setInit(true);
  }, []);
  return init ? <PrivateRoute claims="admin|super" component={AdminRegistration} registrationId={id} /> : null;
}
