import React from 'react';

export default function StandardButton({ action, text }) {
  return (
    <button
      type="button"
      onClick={action}
      className={`bg-gray-200 py-2 text-sm font-medium text-gray-600 rounded-md px-4 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500`}
    >
      {text}
    </button>
  );
}
