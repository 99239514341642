import { navigate } from 'gatsby-link';
import React, { useEffect, useState } from 'react';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import { getApiRequest, postApiRequest } from '../../../../../utils/request';
import Heading from '../../../../layout/admin/heading';
import RegistrationView from '../../bowler/registration-view';
import Loadable from '../../../../data/loadable';
import Panel from '../../../../common/panel';
import SEO from '../../../../layout/seo';
import StandardButton from '../../../../buttons/standard-button';
import FormModal from '../../../../overlays/form-modal';
import Confirm from '../../../../overlays/confirm';
import { useNotifications } from '../../../../../contexts/notifications-provider';

function TeamControls({ registration, reload }) {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const { addNotification } = useNotifications();

  if (!registration || !registration.team) {
    return null;
  }

  const { team } = registration;

  const removeRegistration = async () => {
    setShowConfirm(false);
    try {
      await postApiRequest(`/admin/registration/${registration.id}/remove`);
      navigate('/app/admin/registrations');
      addNotification({
        type: 'success',
        body: 'The registration was removed.',
        timeout: 3000,
      });
    } catch (err) {
      console.log(err, 'error');
      if (err.response && err.response.status === 401) {
        return;
      }
      addNotification({
        type: 'error',
        body: 'There was an error removing the registration.',
      });
      // setLoading(false);
    }
  }


  const renameForm = {
    noContainer: true,
    fetchRoute: `/admin/team/${team.id}`,
    type: 'post',
    postRoute: `/admin/team/${team.id}/registration/${registration.id}/rename`,
    successNotification: 'The team name was updated.',
    successHandler: (values, actions) => {
      actions.resetForm();
      setShowRenameModal(false);
      setTimeout(() => {
        reload();
      }, 250);
    },
    fields: [
      {
        name: 'name',
        label: 'Team Name',
        type: 'text',
        required: true,
      },
    ],
    submitLabel: `Update Team Name`,
  };

  const switchTeamsForm = {
    noContainer: true,
    type: 'post',
    postRoute: `/admin/registration/${registration.id}/switch-team`,
    successNotification: 'The team name was switched.',
    successHandler: (values, actions) => {
      actions.resetForm();
      setShowRenameModal(false);
      setTimeout(() => {
        reload();
      }, 250);
    },
    fields: [
      {
        name: 'team',
        type: 'select',
        optionsUrl: `/options/products/${registration.product_id}/teams`,
        required: true,
        label: 'Choose New Team',
        helpText: 'Note: if this is the last member of a team, the team will no longer show up for this league.',
      }
    ],
    submitLabel: `Switch Teams`,
  };


  return (
    <div className="py-4 px-4 sm:px-6">
      <p className="text-lg my-4">Team Management</p>
      <div className="space-y-4 text-sm text-gray-600 flex-auto md:space-y-0 md:grid md:grid-cols-3 md:gap-x-4">
        <StandardButton action={() => setShowRenameModal(true)} text="Rename Team" />
        <StandardButton action={() => setShowSwitchModal(true)} text="Switch Team" />
        <StandardButton action={() => setShowConfirm(true)} text="Remove Registration" />
      </div>
      <FormModal
        open={showRenameModal}
        form={renameForm}
        cancel={() => setShowRenameModal(false)}
        title="Update Team Name"
        body="Change the name of this team. Note that this will take affect for each member of the team."
      />
      <FormModal
        open={showSwitchModal}
        form={switchTeamsForm}
        cancel={() => setShowSwitchModal(false)}
        title="Switch Teams"
        body="Switch this registration to another team."
      />
      
      <Confirm
        open={showConfirm}
        action={removeRegistration}
        cancel={() => setShowConfirm(false)}
        actionText="Remove"
        title="Remove Registration"
        body="Are you sure you want to remove this registered user?  This cannot be undone. If this user is the sole member of a team, that team will also be removed."
      />
    </div>
  );
}

export default function AdminRegistrationView({ registrationId }) {
  const [loading, setLoading] = useState(true);
  const [registration, setRegistration] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();

  const loadRegistration = async () => {
    setLoading(true);
    try {
      const result = await getApiRequest(`/admin/registration/${registrationId}`);
      setRegistration(result);
    } catch (err) {
      console.log(err, 'error loading registration');
      navigate('/app/admin/registrations');
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/registrations',
        label: 'Registrations',
      },
      {
        href: `/app/admin/registration/view?id=${registrationId}`,
        label: `View Registration`,
      },
    ]);

    loadRegistration();
  }, [registrationId]);

  const { user } = registration || {};


  return (
    <AdminContainer>
      <SEO title="View registration" />
      <Heading title="View Registration" />
      <Loadable loading={loading}>
        <div className="max-w-2xl mx-auto py-12">
          <Panel>
            {!!registration && <RegistrationView registration={registration} user={user} admin />}
            <TeamControls registration={registration} reload={loadRegistration} />
          </Panel>
        </div>
      </Loadable>
    </AdminContainer>
  );
}